import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші послуги | FocusClub
			</title>
			<meta name={"description"} content={"розкрийте свій цифровий потенціал – послуги FocusClub\n"} />
			<meta property={"og:title"} content={"Наші послуги | FocusClub"} />
			<meta property={"og:description"} content={"розкрийте свій цифровий потенціал – послуги FocusClub\n"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15:34:50.679Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15%3A34%3A50.679Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15%3A34%3A50.679Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15%3A34%3A50.679Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15%3A34%3A50.679Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15%3A34%3A50.679Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15%3A34%3A50.679Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/2-1.jpg?v=2024-09-23T15%3A34%3A50.679Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 24px 0px" color="--dark" lg-text-align="center" font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Наші послуги
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					У сфері цифрових ландшафтів, що постійно розвиваються, FocusClub є вашим надійним путівником до успіху. Наші послуги виходять за межі звичайного, пропонуючи стратегічний підхід для підвищення цифрової присутності вашого бренду. Незалежно від того, подорожуєте ви незвіданими територіями чи шукаєте оптимізацію у знайомих сферах, наша команда у FocusClub готова вам допомогти. Ознайомтеся з нашими спеціальними цифровими рішеннями, кожне з яких розроблено для того, щоб спрямовувати ваш бренд до цифрової досконалості. Ласкаво просимо у світ, де інновації зустрічаються зі стратегією, а успіх – це пункт призначення, а не просто подорож.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					text-align="center"
				>
					Звʼязвтися з нами
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-5">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 70px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Цифрова стратегія
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Комплексний аналіз: оцінка вашої поточної цифрової присутності для формулювання індивідуальної стратегії.
						<br />
						<br />
						Позиціонування на ринку: стратегічне планування для ефективного позиціонування вашого бренду в цифровому середовищі.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3">
							Створення контенту
						</Text>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
							Захоплюючий копірайтинг: створення привабливого контенту, адаптованого для вашої цільової аудиторії.
							<br />
							<br />
							Візуальне оповідання: розробка візуально привабливої ​​графіки та мультимедійного вмісту.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Управління соціальними мережами
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Оптимізація платформи: максимізація присутності вашого бренду на ключових платформах соціальних мереж.
						<br />
						<br />
						Розповсюдження контенту: стратегічне планування та розподіл вмісту для оптимального залучення.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Пошукова оптимізація (SEO)
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Оптимізація ключових слів: впровадження ефективних стратегій ключових слів для покращення видимості пошуку.
						<br />
						<br />
						On-Page SEO: покращення вмісту та структури веб-сайту для релевантності пошуковій системі.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Платна реклама
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Цільові кампанії: створення платних рекламних кампаній на різних платформах і керування ними.
						<br />
						<br />
						Оптимізація бюджету: максимізація рентабельності інвестицій за рахунок ефективного розподілу бюджету та націлювання реклами.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
						Аналітика та звітність
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Моніторинг ефективності: безперервне відстеження та аналіз цифрових маркетингових зусиль.
						<br />
						<br />
						Статистична інформація: надання інформації для оптимізації стратегій на основі даних у реальному часі.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Щоб отримати детальну інформацію про наші послуги або обговорити індивідуальний план для вашого бренду, не соромтеся зв’язатися з нами. Ми готові допомогти вам розкрити повний потенціал вашої цифрової присутності.
			</Text>
			<Link
				href="/contact-us"
				padding="12px 24px 12px 24px"
				color="--light"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="20px 16px 0px 0px"
				transition="background-color 0.2s ease-in-out 0s"
				sm-margin="0px 0px 16px 0px"
				sm-text-align="center"
				background="--color-green"
				hover-transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-primary"
				text-align="center"
			>
				Зв'яжіться з нами
			</Link>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});